import { FC } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Activity, Database, Server, TrendingUp } from 'lucide-react';

const data = [
  { name: 'Jan', Storage: 400, Bandwidth: 240, Devices: 24 },
  { name: 'Feb', Storage: 300, Bandwidth: 139, Devices: 22 },
  { name: 'Mar', Storage: 200, Bandwidth: 980, Devices: 29 },
  { name: 'Apr', Storage: 278, Bandwidth: 390, Devices: 20 },
  { name: 'May', Storage: 189, Bandwidth: 480, Devices: 27 },
  { name: 'Jun', Storage: 239, Bandwidth: 380, Devices: 25 },
];

const Stats: FC = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex items-center gap-2 mb-6">
          <Activity className="w-6 h-6 text-[#ed4c4c]" />
          <h1 className="text-2xl font-bold text-gray-800">Stats</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* Total Storage */}
          <div className="bg-white rounded-lg shadow-md p-6 flex items-center">
            <div className="bg-[#ffd0cd] rounded-full p-3 mr-4">
              <Database className="w-6 h-6 text-[#ed4c4c]" />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-gray-800">1.5 TB</h2>
              <p className="text-gray-600">Total Storage</p>
            </div>
          </div>

          {/* Bandwidth Usage */}
          <div className="bg-white rounded-lg shadow-md p-6 flex items-center">
            <div className="bg-[#ffd0cd] rounded-full p-3 mr-4">
              <TrendingUp className="w-6 h-6 text-[#ed4c4c]" />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-gray-800">500 GB</h2>
              <p className="text-gray-600">Bandwidth Usage</p>
            </div>
          </div>

          {/* Active Devices */}
          <div className="bg-white rounded-lg shadow-md p-6 flex items-center">
            <div className="bg-[#ffd0cd] rounded-full p-3 mr-4">
              <Server className="w-6 h-6 text-[#ed4c4c]" />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-gray-800">120</h2>
              <p className="text-gray-600">Active Devices</p>
            </div>
          </div>

          {/* Uptime */}
          <div className="bg-white rounded-lg shadow-md p-6 flex items-center">
            <div className="bg-[#ffd0cd] rounded-full p-3 mr-4">
              <Activity className="w-6 h-6 text-[#ed4c4c]" />
            </div>
            <div>
              <h2 className="text-2xl font-bold text-gray-800">99.9%</h2>
              <p className="text-gray-600">Uptime</p>
            </div>
          </div>
        </div>

        {/* Charts */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Usage Trends</h2>
          <div className="bg-white rounded-lg shadow-md p-6">
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Storage" fill="#ed4c4c" />
                <Bar dataKey="Bandwidth" fill="#faa09a" />
                <Bar dataKey="Devices" fill="#ffd0cd" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stats;