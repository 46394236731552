import { FC, useState } from 'react';
import { Settings as SettingsIcon, User, Bell, Lock, CreditCard, LogOut } from 'lucide-react';

const Settings: FC = () => {
  const [name, setName] = useState('John Doe');
  const [email, setEmail] = useState('johndoe@example.com');
  const [notifications, setNotifications] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleNotificationsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotifications(e.target.checked);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleSaveChanges = () => {
    // Perform save logic here
    console.log('Changes saved');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex items-center gap-2 mb-6">
          <SettingsIcon className="w-6 h-6 text-[#ed4c4c]" />
          <h1 className="text-2xl font-bold text-gray-800">Settings</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Account Settings */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Account Settings</h2>
            <div className="flex items-center gap-4 mb-4">
              <User className="w-6 h-6 text-[#ed4c4c]" />
              <div>
                <label htmlFor="name" className="block font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={handleNameChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ed4c4c] focus:ring focus:ring-[#ffd0cd] focus:ring-opacity-50"
                />
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Bell className="w-6 h-6 text-[#ed4c4c]" />
              <div>
                <label htmlFor="email" className="block font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ed4c4c] focus:ring focus:ring-[#ffd0cd] focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>

          {/* Notification Settings */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Notification Settings</h2>
            <div className="flex items-center gap-4">
              <Bell className="w-6 h-6 text-[#ed4c4c]" />
              <div>
                <label htmlFor="notifications" className="block font-medium text-gray-700">
                  Enable Notifications
                </label>
                <input
                  type="checkbox"
                  id="notifications"
                  checked={notifications}
                  onChange={handleNotificationsChange}
                  className="mt-1 h-4 w-4 rounded border-gray-300 text-[#ed4c4c] focus:ring-[#ffd0cd]"
                />
              </div>
            </div>
          </div>

          {/* Security Settings */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Security Settings</h2>
            <div className="flex items-center gap-4 mb-4">
              <Lock className="w-6 h-6 text-[#ed4c4c]" />
              <div>
                <label htmlFor="password" className="block font-medium text-gray-700">
                  New Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ed4c4c] focus:ring focus:ring-[#ffd0cd] focus:ring-opacity-50"
                />
              </div>
            </div>
            <div className="flex items-center gap-4">
              <Lock className="w-6 h-6 text-[#ed4c4c]" />
              <div>
                <label htmlFor="confirmPassword" className="block font-medium text-gray-700">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#ed4c4c] focus:ring focus:ring-[#ffd0cd] focus:ring-opacity-50"
                />
              </div>
            </div>
          </div>

          {/* Billing Settings */}
          <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Billing Settings</h2>
            <div className="flex items-center gap-4">
              <CreditCard className="w-6 h-6 text-[#ed4c4c]" />
              <div>
                <h3 className="font-medium text-gray-700">Payment Method</h3>
                <p className="text-gray-500">Visa ending in 1234</p>
              </div>
            </div>
          </div>
        </div>

        {/* Save Changes */}
        <div className="mt-6 flex justify-between">
          <button
            onClick={handleSaveChanges}
            className="px-4 py-2 bg-[#ed4c4c] text-white rounded-md hover:bg-[#faa09a] focus:outline-none focus:ring-2 focus:ring-[#ffd0cd]"
          >
            Save Changes
          </button>

          <button className="px-4 py-2 text-gray-600 rounded-md hover:text-[#ed4c4c] focus:outline-none focus:ring-2 focus:ring-[#ffd0cd]">
            <LogOut className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;