/**
 * src/components/devices/config/instructions/MQTTInstructions1.tsx
 * Component for displaying MQTT Node-RED integration instructions and code examples.
 */

import React, { useState } from 'react';
import { Copy, Check, Terminal, Server } from 'lucide-react';

interface MQTTInstructionsProps {
  /** Device identifier used in MQTT topics */
  deviceId: string;
  /** MQTT username for authentication */
  mqttUsername: string;
  /** Full MQTT broker URL including protocol and port */
  brokerUrl: string;
  /** Flag indicating if TLS is enabled */
  isTLS: boolean;
}

/**
 * Displays MQTT connection details and Node-RED integration code.
 */
export const MQTTInstructions: React.FC<MQTTInstructionsProps> = ({
  deviceId,
  mqttUsername,
  brokerUrl,
  isTLS,
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  /**
   * Example Node-RED code for processing video streams.
   */
  const nodeRedCode = `
// Initialize stream tracking
let frameCounter = 0;
let streamId = Date.now();

// Process incoming video stream data
function processStream(msg) {
    if (!msg.payload || !msg.payload.data) {
        node.error("Invalid message format");
        return null;
    }

    frameCounter++;

    const data = {
        type: "frame",
        frameId: frameCounter,
        streamId: streamId,
        timestamp: Date.now(),
        deviceId: "${deviceId}",
        clientId: "${mqttUsername}",
        data: {
            image: msg.payload.data.image.replace(/^data:image\\/jpeg;base64,/, ""),
            boxes: msg.payload.data.boxes || [],
            count: msg.payload.data.count || 0,
            labels: msg.payload.data.labels || [],
            performance: msg.payload.data.perf || [],
            resolution: msg.payload.data.resolution || []
        }
    };

    msg.topic = \`devices/${mqttUsername}/data\`;
    msg.payload = JSON.stringify(data);
    return msg;
}

// Check for stream timeout
if (global.get('lastFrameTime')) {
    const timeSinceLastFrame = Date.now() - global.get('lastFrameTime');
    if (timeSinceLastFrame > 5000) {
        frameCounter = 0;
        streamId = Date.now();
    }
}

global.set('lastFrameTime', Date.now());
return processStream(msg);`.trim();

  /**
   * Copies the Node-RED code to clipboard with visual feedback.
   */
  const handleCopy = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(nodeRedCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy code:', error);
    }
  };

  return (
    <div className="bg-base-100 rounded-lg">
      {/* Header */}
      <div className="p-4 border-b">
        <h3 className="text-lg font-medium flex items-center gap-2">
          <Terminal className="w-5 h-5 text-primary" />
          Node-RED Integration
        </h3>
      </div>

      <div className="p-4 space-y-4">
        {/* Connection Summary */}
        <div className="bg-base-200 rounded-lg p-4">
          <div className="flex items-center gap-2 mb-3">
            <Server className="w-4 h-4 text-primary" />
            <h4 className="font-medium">Connection Details</h4>
          </div>
          <div className="space-y-2 text-sm">
            <div><span className="font-semibold">Broker:</span> {brokerUrl}</div>
            <div><span className="font-semibold">Username:</span> {mqttUsername}</div>
            <div><span className="font-semibold">Topic:</span> devices/{mqttUsername}/data</div>
          </div>
        </div>

        {/* Code Block */}
        <div className="relative">
          <div className="mockup-code bg-base-300 text-base-content">
            <button
              onClick={handleCopy}
              className="absolute top-2 right-2 btn btn-ghost btn-sm tooltip tooltip-left"
              data-tip={copied ? "Copied!" : "Copy code"}
            >
              {copied ? (
                <Check className="w-4 h-4 text-success" />
              ) : (
                <Copy className="w-4 h-4" />
              )}
            </button>
            <pre className="p-4 overflow-x-auto">
              <code>{nodeRedCode}</code>
            </pre>
          </div>
        </div>

        {/* TLS Warning */}
        {isTLS && (
          <div className="alert alert-warning">
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
            <div>
              <h3 className="font-bold">TLS Configuration Required</h3>
              <div className="text-sm">Make sure you have the appropriate CA certificate installed.</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MQTTInstructions;