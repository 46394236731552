import React, { useState, useEffect, useRef } from 'react';
import { Wifi } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { deviceService } from '../../services/deviceService';
import { Device, WebSocketStatus } from '../../types/device';
import LoadScreen from '../Loading';

const ListeningPulse: React.FC = () => {
  return (
    <div className="relative flex items-center justify-center">
      <div className="absolute animate-ping w-8 h-8 rounded-full bg-primary opacity-20"></div>
      <div className="absolute animate-ping w-6 h-6 rounded-full bg-primary opacity-40 delay-75"></div>
      <div className="absolute animate-ping w-4 h-4 rounded-full bg-primary opacity-60 delay-150"></div>
      <div className="relative z-10">
        <Wifi className="w-5 h-5 text-primary" />
      </div>
    </div>
  );
};

interface DeviceListeningProps {
  deviceId: string;
}

const DeviceListening: React.FC<DeviceListeningProps> = ({ deviceId }) => {
  const [device, setDevice] = useState<Device | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [wsStatus, setWsStatus] = useState<WebSocketStatus>('connecting');
  const [messages, setMessages] = useState<any[]>([]);
  const wsRef = useRef<WebSocket | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const connectWebSocket = () => {
      if (wsRef.current?.readyState === WebSocket.OPEN) return;

      const ws = new WebSocket('wss://videobase-websocket.heysalad.app/ws');

      ws.onopen = () => {
        console.log('WebSocket connected, sending auth...');
        setWsStatus('authenticating');
        if (device) {
          const authMessage = {
            type: 'auth',
            deviceId: deviceId,
            clientId: device.clientId
          };
          console.log('Sending auth message:', authMessage);
          ws.send(JSON.stringify(authMessage));
        }
      };

      ws.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          console.log('Received WebSocket message:', data);
          setMessages(prev => [...prev, data]);

          if (data.type === 'auth') {
            if (data.status === 'success') {
              console.log('Authentication successful');
              setWsStatus('connected');
              setDevice(prev => prev ? { ...prev, status: 'online' } : null);
            } else {
              console.error('Authentication failed:', data.message);
              setWsStatus('error');
              setError('Authentication failed: ' + data.message);
            }
          }

          if (data.type === 'frame') {
            console.log('Received frame data:', data);
            // Update last seen timestamp or other relevant device state
            setDevice(prev => prev ? { 
              ...prev, 
              lastSeen: new Date(),
              status: 'online'
            } : null);
          }
        } catch (err) {
          console.error('Error processing WebSocket message:', err, event.data);
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        setWsStatus('error');
        setError('WebSocket connection error');
      };

      ws.onclose = (event) => {
        console.log('WebSocket closed:', event.code, event.reason);
        setWsStatus('disconnected');
        // Attempt to reconnect after a delay
        setTimeout(connectWebSocket, 5000);
      };

      wsRef.current = ws;
    };

    const fetchDeviceData = async () => {
      try {
        const deviceData = await deviceService.getDeviceById(deviceId);
        setDevice(deviceData);
        setIsLoading(false);
        setError(null);
      } catch (err) {
        console.error('Error fetching device data:', err);
        setError('Unable to fetch device data. Please try again later.');
        setIsLoading(false);
      }
    };

    const pollDeviceData = () => {
      fetchDeviceData();
      const timeoutId = setTimeout(pollDeviceData, 5000);
      return () => clearTimeout(timeoutId);
    };

    if (deviceId) {
      fetchDeviceData();
      const cleanup = pollDeviceData();
      connectWebSocket();
      return () => {
        cleanup();
        if (wsRef.current) {
          wsRef.current.close();
        }
      };
    }
  }, [deviceId, device?.clientId]);

  const renderDebugInfo = () => (
    <div className="mt-4 p-4 bg-base-200 rounded-lg">
      <h3 className="font-semibold mb-2">Debug Information</h3>
      <div className="space-y-2">
        <p>WebSocket Status: {wsStatus}</p>
        <p>Device ID: {deviceId}</p>
        <p>Client ID: {device?.clientId}</p>
        <p>Last Seen: {device?.lastSeen ? new Date(device.lastSeen).toLocaleString() : 'Never'}</p>
        <p>Connection Status: {wsRef.current?.readyState}</p>
        <div className="mt-4">
          <h4 className="font-semibold">Last 5 Messages:</h4>
          <pre className="text-xs mt-2 bg-base-300 p-2 rounded overflow-auto max-h-40">
            {JSON.stringify(messages.slice(-5), null, 2)}
          </pre>
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="min-h-screen">
        <LoadScreen />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-white">
        <div className="text-center">
          <p className="text-red-500">{error}</p>
          <button 
            className="btn btn-primary mt-4" 
            onClick={() => window.location.reload()}
          >
            Retry
          </button>
          {renderDebugInfo()}
        </div>
      </div>
    );
  }

  if (!device || wsStatus !== 'connected') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-white space-y-4">
        <div className="mb-4">
          <ListeningPulse />
        </div>
        <p>Waiting for the device to come online...</p>
        <p className="text-sm text-gray-500">Connection status: {wsStatus}</p>
        {renderDebugInfo()}
      </div>
    );
  }

  return (
    <div className="p-4">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <div className="flex justify-between items-start">
            <h2 className="card-title">Device Connected</h2>
            <ListeningPulse />
          </div>
          <p>{device.name} is now connected!</p>
          {renderDebugInfo()}
          <div className="card-actions justify-end">
            <button
              className="btn btn-primary"
              onClick={() => navigate(`/devices/${device.id}`)}
            >
              View Device Info
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceListening;