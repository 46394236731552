import React from 'react';
import { Copy } from 'lucide-react';
import { ProtocolConfigProps } from './types';

export const WebSocketConfig: React.FC<ProtocolConfigProps> = ({
  onCopyUrl
}) => {
  const url = 'wss://videobase-websocket.heysalad.app/ws';

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">WebSocket URL</span>
      </label>
      <div className="input-group">
        <input
          type="text"
          value={url}
          readOnly
          className="input input-bordered w-full font-mono text-sm"
        />
        <button 
          onClick={() => onCopyUrl(url)} 
          className="btn btn-square"
          title="Copy URL"
        >
          <Copy className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default WebSocketConfig;