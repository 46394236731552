import React from 'react';
import { Device } from '../../../types/device';

interface DeviceInfoSectionProps {
  device: Device;
  name: string;
  onNameChange: (name: string) => void;
}

export const DeviceInfoSection: React.FC<DeviceInfoSectionProps> = ({
  device,
  name,
  onNameChange,
}) => (
  <div className="mb-6">
    <h3 className="text-lg font-semibold mb-4">Device Information</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Device Name</span>
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Device Type</span>
        </label>
        <input
          type="text"
          value={device.deviceType}
          readOnly
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Device ID</span>
        </label>
        <input
          type="text"
          value={device.id}
          readOnly
          className="input input-bordered"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Client ID</span>
        </label>
        <input
          type="text"
          value={device.clientId}
          readOnly
          className="input input-bordered"
        />
      </div>
    </div>
  </div>
);

export default DeviceInfoSection;