import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Cloud, Bot, Video } from 'lucide-react';
import icon from '../assets/icon.svg';
import recameraImg from '../assets/recamera-photo.svg';
import groveAiImg from '../assets/grove-ai.svg';

const Landing: React.FC = () => {
  const [currentPhrase, setCurrentPhrase] = useState(0);
  const phrases = [
    "Simplify IoT Video Management",
    "Streamline Device Deployment",
    "Analyze Vision AI Models",
    "Scale Your IoT Fleet"
  ];

  const subPhrases = [
    "with Vision AI",
    "in Minutes",
    "with Ease",
    "Seamlessly"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhrase((prev) => (prev + 1) % phrases.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="min-h-screen">
      {/* Sticky Navigation */}
      <nav className="bg-white border-b sticky top-0 z-40 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center space-x-2">
              <img src={icon} alt="VideoBase Icon" className="h-8 w-8" />
              <h1 className="text-2xl font-bold text-black">VideoBase</h1>
            </div>
            <div className="flex space-x-4">
              <Link to="/login" className="btn btn-ghost text-black hover:bg-gray-100 text-sm sm:text-base">Sign in</Link>
              <Link to="/signup" className="btn bg-[#ed4c4c] text-white hover:bg-[#c43c3c] text-sm sm:text-base">Get Started</Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section with Animated Text */}
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 lg:mt-16">
              <div className="text-center relative">
                {/* Recording Indicator */}
                <div className="absolute top-0 left-4 sm:left-8 flex items-center space-x-2">
                  <div className="w-3 h-3 bg-[#ed4c4c] rounded-full animate-pulse" />
                  <span className="text-sm text-gray-600">Recording..</span>
                </div>
                
                <h2 className="text-3xl tracking-tight font-extrabold sm:text-5xl md:text-6xl min-h-[160px] sm:min-h-[128px]">
                  <span className="block transition-opacity duration-500 animate-fade-in">
                    {phrases[currentPhrase]}
                  </span>
                  <span className="block text-[#ed4c4c] transition-opacity duration-500 animate-fade-in">
                    {subPhrases[currentPhrase]}
                  </span>
                </h2>
                <p className="mt-3 text-base sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl text-gray-700">
                  Deploy, manage, and analyze your IoT camera fleet with ease. 
                  Powered by advanced Vision AI technology.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                  <div className="rounded-md shadow">
                    <Link
                      to="/signup"
                      className="btn bg-[#ed4c4c] btn-lg text-white hover:bg-[#c43c3c] transform transition hover:scale-105"
                    >
                      Start Free Trial
                      <ChevronRight className="ml-2 animate-bounce" />
                    </Link>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 sm:py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-[#ed4c4c]">Powerful Features</h2>
          </div>
          <div className="mt-12 grid gap-8 grid-cols-1 md:grid-cols-3">
            <div className="card bg-white shadow-xl hover:shadow-2xl transition-all hover:-translate-y-2">
              <div className="card-body items-center text-center p-6">
                <Video className="w-12 h-12 text-[#ed4c4c]" />
                <h3 className="card-title mt-4 text-[#ed4c4c]">Easy Device Management</h3>
                <p className="text-gray-700">
                  Provision and manage video streaming devices with just a few clicks. 
                  Seamless deployment across your entire fleet.
                </p>
              </div>
            </div>
            <div className="card bg-white shadow-xl hover:shadow-2xl transition-all hover:-translate-y-2">
              <div className="card-body items-center text-center p-6">
                <Cloud className="w-12 h-12 text-[#ed4c4c]" />
                <h3 className="card-title mt-4 text-[#ed4c4c]">Smart Storage</h3>
                <p className="text-gray-700">
                  Efficiently store and organize your video and image data with 
                  intelligent cloud management and automatic backups.
                </p>
              </div>
            </div>
            <div className="card bg-white shadow-xl hover:shadow-2xl transition-all hover:-translate-y-2">
              <div className="card-body items-center text-center p-6">
                <Bot className="w-12 h-12 text-[#ed4c4c]" />
                <h3 className="card-title mt-4 text-[#ed4c4c]">Vision AI Tools</h3>
                <p className="text-gray-700">
                  Evaluate and deploy Vision AI models to your remote IoT devices. 
                  Real-time analytics and insights at your fingertips.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="py-12 sm:py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 text-center">
            <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-4xl font-bold text-[#ed4c4c]">99.9%</h3>
              <p className="mt-2 text-gray-600">Uptime Guarantee</p>
            </div>
            <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow">
              <h3 className="text-4xl font-bold text-[#ed4c4c]">10x</h3>
              <p className="mt-2 text-gray-600">Faster Deployment</p>
            </div>
            <div className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow sm:col-span-2 md:col-span-1">
              <h3 className="text-4xl font-bold text-[#ed4c4c]">24/7</h3>
              <p className="mt-2 text-gray-600">Expert Support</p>
            </div>
          </div>
        </div>
      </div>

      {/* Supported Devices Section */}
      <div className="py-12 sm:py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-[#ed4c4c] mb-4">Supported Devices</h2>
            <p className="text-gray-600 max-w-2xl mx-auto mb-12">
              Our platform supports a growing ecosystem of IoT vision devices, 
              enabling you to leverage the latest in edge computing and AI technology.
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="card bg-white hover:shadow-2xl transition-all hover:-translate-y-2 border border-gray-100">
              <div className="card-body items-center text-center p-8">
                <div className="bg-white rounded-full p-8 mb-6">
                  <img 
                    src={recameraImg} 
                    alt="Seeedstudio reCamera" 
                    className="w-24 sm:w-32 h-24 sm:h-32 object-contain transform transition-transform hover:scale-110"
                  />
                </div>
                <h3 className="card-title text-[#ed4c4c] text-xl sm:text-2xl">Seeedstudio reCamera</h3>
                <p className="text-gray-700 my-4">
                  A powerful IoT camera platform designed for edge computing and AI applications.
                  Perfect for real-time video analytics and processing.
                </p>
                <div className="card-actions mt-4">
                  <a 
                    href="https://www.seeedstudio.com/reCamera.html" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="btn bg-[#ed4c4c] text-white hover:bg-[#c43c3c] transform transition hover:scale-105"
                  >
                    Learn More
                    <ChevronRight className="ml-2" />
                  </a>
                </div>
              </div>
            </div>

            <div className="card bg-white hover:shadow-2xl transition-all hover:-translate-y-2 border border-gray-100">
              <div className="card-body items-center text-center p-8">
                <div className="bg-white rounded-full p-8 mb-6">
                  <img 
                    src={groveAiImg} 
                    alt="Seeedstudio Grove AI" 
                    className="w-24 sm:w-32 h-24 sm:h-32 object-contain transform transition-transform hover:scale-110"
                  />
                </div>
                <h3 className="card-title text-[#ed4c4c] text-xl sm:text-2xl">Seeedstudio Grove AI</h3>
                <p className="text-gray-700 my-4">
                  An advanced AI vision module that excels in real-time image processing and analysis.
                  Built for sophisticated computer vision applications.
                </p>
                <div className="card-actions mt-4">
                  <a 
                    href="https://www.seeedstudio.com/Grove-Vision-AI-Module-p-5457.html" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="btn bg-[#ed4c4c] text-white hover:bg-[#c43c3c] transform transition hover:scale-105"
                  >
                    Learn More
                    <ChevronRight className="ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-[#ed4c4c] py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl sm:text-3xl font-bold text-white mb-6">Ready to Transform Your IoT Vision?</h2>
          <Link 
            to="/signup" 
            className="btn bg-white text-[#ed4c4c] hover:bg-gray-100 transform transition hover:scale-105"
          >
            Start Free Trial
            <ChevronRight className="ml-2" />
          </Link>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="flex items-center justify-center space-x-2 mb-4">
              <img src={icon} alt="VideoBase Icon" className="h-8 w-8" />
              <h3 className="text-xl font-bold text-black">VideoBase</h3>
            </div>
            <p className="text-gray-600">Empowering IoT Vision Intelligence</p>
            <div className="mt-6 flex justify-center space-x-6">
              <Link to="/privacy" className="text-gray-600 hover:text-black">Privacy</Link>
              <Link to="/terms" className="text-gray-600 hover:text-black">Terms</Link>
              <Link to="/contact" className="text-gray-600 hover:text-black">Contact</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Landing;