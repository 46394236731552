/**
 * src/components/devices/DeviceCard.tsx
 * A component for displaying and managing device information.
 */

import React, { useState } from 'react';
import { Wifi, WifiOff, Settings, Image, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Device } from '../../types/device';
import { DeviceConfig } from './DeviceConfig';
import { deviceService } from '../../services/deviceService';
import recameraImg from '../../assets/recamera-photo.svg';
import groveAiImg from '../../assets/grove-ai.svg';

/**
 * Props interface for the DeviceCard component.
 */
interface DeviceCardProps {
  /** The device object containing all device information */
  device: Device;
  /** Callback function for updating device information */
  onUpdate: (updates: Partial<Device>) => Promise<void>;
  /** Callback function for deleting the device */
  onDelete: () => Promise<void>;
  /** Optional callback for closing the device panel */
  onClose?: () => void;
  /** Flag to determine if the card should render as a panel */
  isPanel?: boolean;
  /** Optional click handler for the card */
  onClick?: () => void;
}

/**
 * A component that displays device information and provides configuration options.
 */
const DeviceCard: React.FC<DeviceCardProps> = ({
  device,
  onUpdate,
  onDelete,
  onClose,
  isPanel = false,
  onClick
}) => {
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const navigate = useNavigate();

  /**
   * Returns the appropriate device icon based on device type.
   */
  const getDeviceIcon = (): string => {
    return device.deviceType === 'reCamera' ? recameraImg : groveAiImg;
  };

  /**
   * Formats a given date into a human-readable "time ago" string.
   */
  const formatTimeAgo = (date: Date): string => {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 60) return `${seconds} seconds ago`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} minutes ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hours ago`;
    const days = Math.floor(hours / 24);
    return `${days} days ago`;
  };

  /**
   * Handles navigation to device data view.
   */
  const handleViewData = (): void => {
    navigate(`/devices/${device.id}/data`);
  };

  /**
   * Handles the configure button click.
   */
  const handleConfigure = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setShowConfig(true);
  };

  /**
   * Updates device information through the service layer.
   */
  const handleDeviceUpdate = async (updates: Partial<Device>): Promise<void> => {
    try {
      await deviceService.updateDevice(device.id, updates);
      await onUpdate(updates);
    } catch (error) {
      console.error('Error updating device:', error);
      throw error;
    }
  };

  /**
   * Handles device deletion through the service layer.
   */
  const handleDeviceDelete = async (): Promise<void> => {
    try {
      await deviceService.deleteDevice(device.id);
      await onDelete();
    } catch (error) {
      console.error('Error deleting device:', error);
      throw error;
    }
  };

  /**
   * Renders the main content of the device card.
   */
  const CardContent = () => (
    <>
      {/* Header Section */}
      <div className="flex justify-between items-start">
        <div className="flex items-center gap-4">
          <div className="bg-white rounded-lg p-2 shadow-sm">
            <img 
              src={getDeviceIcon()} 
              alt={device.deviceType} 
              className="w-12 h-12 object-contain"
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-gray-800">{device.name}</h2>
            <div className="flex items-center gap-2 mt-1">
              <span className={`inline-flex items-center gap-1.5 px-2 py-0.5 rounded-full text-xs font-medium
                ${device.status === 'online' 
                  ? 'bg-green-100 text-green-700' 
                  : 'bg-gray-100 text-gray-600'}`}>
                {device.status === 'online' ? (
                  <Wifi className="w-3 h-3" />
                ) : (
                  <WifiOff className="w-3 h-3" />
                )}
                {device.status}
              </span>
              <span className="text-sm text-gray-500">
                {device.deviceType === 'reCamera' ? 'reCamera' : 'Grove AI'}
              </span>
            </div>
          </div>
        </div>

        <div className="tooltip" data-tip={device.protocol || 'websocket'}>
          <div className="text-xs bg-[#ffd0cd] text-[#ed4c4c] px-2 py-1 rounded uppercase">
            {device.protocol || 'websocket'}
          </div>
        </div>
      </div>

      {/* Device Info Grid */}
      <div className="mt-4 grid grid-cols-2 gap-2 text-sm">
        <div className="bg-gray-50 p-2 rounded">
          <span className="text-gray-500 text-xs">Device ID</span>
          <p className="font-mono text-xs truncate" title={device.id}>
            {device.id}
          </p>
        </div>
        <div className="bg-gray-50 p-2 rounded">
          <span className="text-gray-500 text-xs">Client ID</span>
          <p className="font-mono text-xs truncate" title={device.clientId}>
            {device.clientId}
          </p>
        </div>
        <div className="bg-gray-50 p-2 rounded col-span-2">
          <span className="text-gray-500 text-xs">Last Seen</span>
          <p className="text-xs">
            {formatTimeAgo(new Date(device.lastSeen))}
            <span className="text-gray-400 ml-1">
              ({new Date(device.lastSeen).toLocaleString()})
            </span>
          </p>
        </div>
      </div>

      {/* Storage Info */}
      {device.storage && (
        <div className="mt-2 p-2 bg-gray-50 rounded text-xs">
          <div className="flex items-center gap-2">
            <span className="text-gray-500">Storage:</span>
            {device.storage.local && (
              <span className="bg-[#ffd0cd] text-[#ed4c4c] px-1.5 py-0.5 rounded">
                Local
              </span>
            )}
            {device.storage.supabase && (
              <span className="bg-[#ffd0cd] text-[#ed4c4c] px-1.5 py-0.5 rounded">
                Supabase
              </span>
            )}
            {device.storage.google && (
              <span className="bg-[#ffd0cd] text-[#ed4c4c] px-1.5 py-0.5 rounded">
                Google
              </span>
            )}
          </div>
        </div>
      )}

      {/* Actions */}
      <div className="card-actions justify-end mt-4 pt-2 border-t border-gray-100">
        <button
          onClick={handleConfigure}
          className="btn btn-sm bg-[#ffd0cd] hover:bg-[#faa09a] border-none text-[#ed4c4c] gap-1"
        >
          <Settings className="w-3 h-3" />
          Configure
        </button>
        <button 
          onClick={handleViewData}
          className="btn btn-sm bg-[#ed4c4c] hover:bg-[#faa09a] text-white border-none gap-1"
        >
          <Image className="w-3 h-3" />
          View Data
        </button>
      </div>
    </>
  );

  if (isPanel) {
    return (
      <div className="fixed inset-y-0 right-0 w-[600px] bg-white shadow-2xl flex flex-col z-50">
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <h2 className="text-xl font-bold">Device Information</h2>
          {onClose && (
            <button onClick={onClose} className="btn btn-ghost btn-sm">
              <X className="w-4 h-4" />
            </button>
          )}
        </div>
        <div className="flex-1 overflow-y-auto p-6">
          <CardContent />
        </div>
      </div>
    );
  }

  return (
    <>
      <div 
        className="card bg-white hover:shadow-lg transition-all duration-300 relative overflow-hidden cursor-pointer"
        onClick={onClick}
      >
        <div 
          className={`absolute top-0 left-0 right-0 h-1 ${
            device.status === 'online' ? 'bg-green-500' : 'bg-gray-300'
          }`}
        />
        <div className="card-body p-4">
          <CardContent />
        </div>
      </div>

      {showConfig && (
        <DeviceConfig
          device={device}
          onClose={() => setShowConfig(false)}
          onUpdate={handleDeviceUpdate}
          onDelete={handleDeviceDelete}
        />
      )}
    </>
  );
};

export default DeviceCard;