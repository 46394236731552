import React, { useState } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Home, Camera, Video, BarChart2, Settings, LogOut, ChevronLeft, ChevronRight } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import iconSvg from '../assets/icon.svg';

export const DashboardLayout: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const navItems = [
    { path: '/dashboard', icon: Home, label: 'Dashboard' },
    { path: '/devices', icon: Camera, label: 'Devices' },
    { path: '/viewer', icon: Video, label: 'Viewer' },
    { path: '/stats', icon: BarChart2, label: 'Stats' },
    { path: '/settings', icon: Settings, label: 'Settings' },
  ];

  const isActive = (path: string) => location.pathname === path;

  return (
    <div className="min-h-screen flex bg-base-200">
      {/* Sidebar */}
      <div className={`${isExpanded ? 'w-64' : 'w-20'} bg-base-100 shadow-xl transition-all duration-300 flex flex-col`}>
        {/* Logo and Toggle */}
        <div className="p-4 flex items-center justify-between border-b border-base-300">
          <div className="flex items-center space-x-3">
            <img src={iconSvg} alt="VideoBase" className="w-8 h-8" />
            {isExpanded && <h1 className="text-xl font-bold">VideoBase</h1>}
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="btn btn-ghost btn-sm btn-square"
          >
            {isExpanded ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
          </button>
        </div>
        
        {/* User info */}
        <div className="p-4 border-b border-base-300">
          <div className="flex items-center space-x-3">
            <div className="avatar placeholder">
              <div className="bg-neutral text-neutral-content rounded-full w-8">
                <span>{user?.email?.[0].toUpperCase()}</span>
              </div>
            </div>
            {isExpanded && (
              <div className="overflow-hidden">
                <div className="font-bold truncate">{user?.email}</div>
              </div>
            )}
          </div>
        </div>

        {/* Navigation */}
        <nav className="p-4 flex-1">
          <ul className="space-y-2">
            {navItems.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`flex items-center ${isExpanded ? 'space-x-3' : 'justify-center'} p-2 rounded-lg hover:bg-base-200 transition-colors ${
                    isActive(item.path) ? 'bg-primary/10 text-primary' : ''
                  }`}
                  title={!isExpanded ? item.label : undefined}
                >
                  <item.icon size={20} />
                  {isExpanded && <span>{item.label}</span>}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        {/* Sign Out Button */}
        <div className="p-4 border-t border-base-300">
          <button 
            onClick={handleSignOut}
            className={`btn btn-outline w-full ${!isExpanded && 'btn-square'}`}
            title={!isExpanded ? 'Sign Out' : undefined}
          >
            <LogOut size={20} />
            {isExpanded && <span>Sign Out</span>}
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 overflow-x-hidden">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;