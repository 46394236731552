import { FC } from 'react';
import { Copy, Plus, Activity, Settings, WifiOff, Wifi, Globe, Network } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const RECENT_ACTIVITIES = [
  { id: 1, deviceName: 'Kitchen Camera', event: 'Started streaming', time: '2 mins ago' },
  { id: 2, deviceName: 'Entry Sensor', event: 'Detected motion', time: '5 mins ago' },
  { id: 3, deviceName: 'Garage Camera', event: 'Stopped streaming', time: '15 mins ago' }
];

const MY_DEVICES = [
  { id: 1, name: 'Kitchen Camera', type: 'reCamera', status: 'online', lastSeen: '2 mins ago' },
  { id: 2, name: 'Entry Sensor', type: 'groveAI', status: 'online', lastSeen: '1 min ago' },
  { id: 3, name: 'Garage Camera', type: 'reCamera', status: 'offline', lastSeen: '15 mins ago' }
];
const Dashboard: FC = () => {
  const navigate = useNavigate();

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const ConnectionCard = ({ protocol, icon: Icon, url, description }: any) => (
    <div className="bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-all h-full">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 rounded bg-[#ffd0cd] flex items-center justify-center">
            <Icon className="w-4 h-4 text-[#ed4c4c]" />
          </div>
          <h3 className="text-sm font-medium text-gray-800">{protocol}</h3>
        </div>
        <button 
          onClick={() => handleCopyToClipboard(url)}
          className="btn btn-xs btn-ghost hover:bg-[#ffd0cd]"
        >
          <Copy className="w-3 h-3" />
        </button>
      </div>
      <p className="text-xs text-gray-600 mb-2">{description}</p>
      <code className="text-xs bg-white rounded p-2 block text-gray-700 border border-gray-200 overflow-x-auto">
        {url}
      </code>
    </div>
  );

  return (
    <div className="p-4 max-w-[1600px] mx-auto">
      {/* Header and Quick Stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
        <div className="md:col-span-2 lg:col-span-5 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800">Dashboard</h1>
          <button 
            onClick={() => navigate('/devices/new')}
            className="btn btn-sm bg-[#ed4c4c] hover:bg-[#faa09a] text-white border-none gap-2"
          >
            <Plus className="w-4 h-4" />
            Add Device
          </button>
        </div>
        
        {/* Compact Stats */}
        <div className="card bg-white shadow-sm">
          <div className="card-body p-3">
            <div className="flex justify-between items-center mb-1">
              <h3 className="text-sm font-medium text-gray-600">Storage</h3>
              <span className="text-xs text-gray-500">1GB</span>
            </div>
            <div className="w-full bg-[#ffd0cd] rounded-full h-2">
              <div className="bg-[#ed4c4c] h-2 rounded-full w-[40%]"></div>
            </div>
          </div>
        </div>

        <div className="card bg-white shadow-sm">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-600">Active</h3>
              <span className="text-[#ed4c4c] font-semibold">12</span>
            </div>
            <p className="text-xs text-gray-500">3 streaming</p>
          </div>
        </div>

        <div className="card bg-white shadow-sm">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-600">Data</h3>
              <span className="text-[#ed4c4c] font-semibold">2.4GB</span>
            </div>
            <p className="text-xs text-gray-500">Last 24h</p>
          </div>
        </div>

        <div className="card bg-white shadow-sm">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-600">Uptime</h3>
              <span className="text-[#ed4c4c] font-semibold">99.9%</span>
            </div>
            <p className="text-xs text-gray-500">30 days</p>
          </div>
        </div>

        <div className="card bg-white shadow-sm">
          <div className="card-body p-3">
            <div className="flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-600">Status</h3>
              <span className="badge badge-success badge-sm">Healthy</span>
            </div>
            <p className="text-xs text-gray-500">All systems</p>
          </div>
        </div>
      </div>

      {/* Main Grid: Devices, Activity, and Connections side by side */}
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
        {/* Devices Section */}
        <div className="lg:col-span-5 card bg-white shadow-sm">
          <div className="card-body p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-gray-800">My Devices</h2>
              <button 
                onClick={() => navigate('/devices')}
                className="btn btn-xs bg-[#ffd0cd] hover:bg-[#faa09a] border-none text-[#ed4c4c] gap-1"
              >
                <Settings className="w-3 h-3" />
                Manage
              </button>
            </div>
            <div className="space-y-2">
              {MY_DEVICES.map(device => (
                <div 
                  key={device.id}
                  className="flex items-center justify-between p-2 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer"
                  onClick={() => navigate(`/devices/${device.id}`)}
                >
                  <div className="flex items-center gap-3">
                    {device.status === 'online' ? (
                      <Wifi className="w-4 h-4 text-green-500" />
                    ) : (
                      <WifiOff className="w-4 h-4 text-gray-400" />
                    )}
                    <div>
                      <h3 className="text-sm font-medium text-gray-800">{device.name}</h3>
                      <p className="text-xs text-gray-500">Last seen {device.lastSeen}</p>
                    </div>
                  </div>
                  <span className={`text-xs px-2 py-1 rounded ${
                    device.status === 'online' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-600'
                  }`}>
                    {device.type}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Recent Activity */}
        <div className="lg:col-span-3 card bg-white shadow-sm">
          <div className="card-body p-4">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">Recent Activity</h2>
            <div className="space-y-3">
              {RECENT_ACTIVITIES.map(activity => (
                <div key={activity.id} className="flex gap-2">
                  <div className="w-1.5 h-1.5 mt-1.5 rounded-full bg-[#ed4c4c]"></div>
                  <div>
                    <h3 className="text-sm font-medium text-gray-800">{activity.deviceName}</h3>
                    <p className="text-xs text-gray-500">{activity.event}</p>
                    <span className="text-xs text-gray-400">{activity.time}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Connection Info */}
        <div className="lg:col-span-4 card bg-white shadow-sm">
          <div className="card-body p-4">
            <h2 className="text-lg font-semibold text-gray-800 mb-4">Connection Info</h2>
            <div className="grid grid-cols-1 gap-3">
              <ConnectionCard 
                protocol="HTTPS"
                icon={Globe}
                url="https://videobase-api.heysalad.app/v1/devices/data"
                description="RESTful API endpoint"
              />
              <ConnectionCard 
                protocol="WebSocket"
                icon={Activity}
                url="wss://videobase-websocket.heysalad.app/ws"
                description="Real-time communication"
              />
              <ConnectionCard 
                protocol="MQTTS"
                icon={Network}
                url="mqtts://videobase-mqtt.heysalad.app:8883"
                description="Secure MQTT (TLS)"
              />
              <ConnectionCard 
                protocol="MQTT"
                icon={Network}
                url="mqtt://videobase-mqtt.heysalad.app:1883"
                description="Standard MQTT"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;