/**
 * src/components/devices/DeviceConfig.tsx
 * Main configuration component for device management.
 * 
 * @fileoverview This component provides interfaces for managing device settings,
 * including protocol selection, credentials, and connection configuration.
 */

import React, { useState } from 'react';
import type { Device, Protocol } from '../../types/device';
import { Settings, X } from 'lucide-react';
import { 
  MQTTConfig, 
  HTTPSConfig, 
  WebSocketConfig,
  NodeREDSection,
  DeviceInfoSection, 
  ProtocolToggle 
} from './config';

/**
 * Props interface for the DeviceConfig component.
 */
interface DeviceConfigProps {
  /** Device being configured */
  device: Device;
  /** Callback function to close the configuration modal */
  onClose?: () => void;
  /** Callback function to update device settings */
  onUpdate: (updates: Partial<Device>) => Promise<void>;
  /** Callback function to delete the device */
  onDelete: () => Promise<void>;
}

/**
 * A modal component for managing device configuration settings.
 * Provides interfaces for protocol selection, credential management, and device updates.
 */
export const DeviceConfig: React.FC<DeviceConfigProps> = ({
  device,
  onClose,
  onUpdate,
  onDelete,
}): JSX.Element => {
  // State management
  const [name, setName] = useState<string>(device.name);
  const [protocol, setProtocol] = useState<Protocol>(device.protocol || 'mqtt');
  const [isTLS, setIsTLS] = useState<boolean>(protocol === 'mqtts');
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  /**
   * Handles saving device configuration changes.
   */
  const handleSave = async (): Promise<void> => {
    try {
      setSaving(true);
      setError(null);
      await onUpdate({
        name,
        protocol: isTLS && protocol === 'mqtt' ? 'mqtts' : protocol
      });
      onClose?.();
    } catch (err) {
      console.error('Error saving device config:', err);
      setError('Failed to save changes. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  /**
   * Handles copying text to clipboard with error handling.
   */
  const handleCopyUrl = async (url: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  /**
   * Renders the protocol-specific configuration component.
   */
  const renderProtocolConfig = (): JSX.Element => {
    switch (protocol) {
      case 'mqtt':
      case 'mqtts':
        return (
          <div className="card bg-base-100">
            <div className="card-body">
              <h3 className="card-title">MQTT Configuration</h3>
              <MQTTConfig
                device={device}
                mqttUsername={device.mqttUsername || `device_${device.id}`}
                mqttPassword={device.mqttPassword || ''}
                onUpdateCredentials={async (username: string, password: string) => {
                  await onUpdate({
                    mqttUsername: username,
                    mqttPassword: password,
                    protocol: isTLS ? 'mqtts' : 'mqtt'
                  });
                }}
                onCopyUrl={handleCopyUrl}
                isTLS={isTLS}
              />
            </div>
          </div>
        );
      
      case 'https':
        return (
          <div className="card bg-base-100">
            <div className="card-body">
              <h3 className="card-title">HTTPS Configuration</h3>
              <HTTPSConfig
                device={device}
                onUpdateApiKey={async (apiKey: string) => {
                  await onUpdate({
                    clientId: apiKey,
                    protocol: 'https'
                  });
                }}
                onCopyUrl={handleCopyUrl}
              />
            </div>
          </div>
        );
      
      case 'websocket':
        return (
          <div className="card bg-base-100">
            <div className="card-body">
              <h3 className="card-title">WebSocket Configuration</h3>
              <WebSocketConfig
                device={device}
                onCopyUrl={handleCopyUrl}
              />
            </div>
          </div>
        );
      
      default:
        return (
          <div className="alert alert-warning">
            <span>Please select a protocol to configure</span>
          </div>
        );
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-end">
      {/* Backdrop */}
      <div 
        className="absolute inset-0 bg-black/20 backdrop-blur-sm"
        onClick={onClose}
      />
      
      {/* Configuration Panel */}
      <div className="relative w-[600px] bg-base-100 shadow-2xl animate-slide-in-right flex flex-col">
        {/* Header */}
        <div className="px-6 py-4 border-b flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Settings className="w-5 h-5" />
            <h2 className="text-xl font-bold">Device Configuration</h2>
          </div>
          {onClose && (
            <button 
              onClick={onClose}
              className="btn btn-ghost btn-sm btn-circle"
            >
              <X className="w-4 h-4" />
            </button>
          )}
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-6 space-y-6">
            {/* Error Display */}
            {error && (
              <div className="alert alert-error">
                <i className="fas fa-exclamation-circle" />
                <span>{error}</span>
              </div>
            )}

            {/* Device Information */}
            <DeviceInfoSection
              device={device}
              name={name}
              onNameChange={setName}
            />

            {/* Protocol Selection */}
            <ProtocolToggle
              selectedProtocol={protocol}
              onChange={setProtocol}
              onTLSChange={setIsTLS}
              isTLS={isTLS}
            />

            {/* Protocol Configuration */}
            {renderProtocolConfig()}

            {/* Node-RED Integration */}
            <NodeREDSection
              code={`// Node-RED function for ${device.id}\n// Protocol: ${protocol}`}
              onCopyCode={() => {}}
              protocol={protocol}
            />
          </div>
        </div>

        {/* Actions */}
        <div className="border-t p-4 flex justify-between">
          <button 
            className="btn btn-error"
            onClick={onDelete}
            disabled={saving}
          >
            Delete Device
          </button>
          <div className="flex gap-2">
            <button
              className="btn btn-ghost"
              onClick={onClose}
              disabled={saving}
            >
              Cancel
            </button>
            <button
              className={`btn btn-primary ${saving ? 'loading' : ''}`}
              onClick={handleSave}
              disabled={saving}
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceConfig;