import React, { useEffect, useRef, useState } from "react";
import HappyTomato from "../components/animations/HappyTomato";

const LoadScreen: React.FC = () => {
  const progressRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [, setDimensions] = useState<{ width: number; height: number }>({ width: 0, height: 0 });

  useEffect(() => {
    if (progressRef.current && containerRef.current) {
      // Adjust the animation size to fit the screen
      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const aspectRatio = 500 / 500; // Assuming the original size is 500x500

      let newWidth: number = containerWidth * 0.8; // Scaled down by 80%
      let newHeight: number = containerHeight * 0.8; // Scaled down by 80%

      if (containerWidth / containerHeight > aspectRatio) {
        newWidth = containerWidth * 0.8;
        newHeight = containerWidth * 0.8 / aspectRatio;
      } else {
        newWidth = containerHeight * 0.8 * aspectRatio;
        newHeight = containerHeight * 0.8;
      }

      setDimensions({ width: newWidth, height: newHeight });
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className="flex flex-col items-center justify-center min-h-screen bg-white px-5"
    >
      {/* Animation */}
      <div className="mb-12 bg-white">
        <HappyTomato />
      </div>

      {/* Loading Text */}
      <h2
        className="text-2xl mb-5 text-center font-grandstander"
        style={{ fontFamily: 'GrandStander, cursive' }}
      >
        We are speaking to your camera, so you can get cooking!
      </h2>

      {/* Progress Bar Container */}
      <div
        ref={progressRef}
        className="w-4/5 max-w-md bg-gray-200 rounded-full overflow-hidden"
      />
    </div>
  );
};

export default LoadScreen;