// services/deviceService.ts
import { createClient } from '@supabase/supabase-js';
import { Device, DeviceStatus, DeviceType, Protocol } from '../types/device';

// Environment variable validation
const validateEnv = () => {
    const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
    const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

    if (!supabaseUrl) {
        throw new Error('VITE_SUPABASE_URL is not set in environment variables');
    }
    if (!supabaseKey) {
        throw new Error('VITE_SUPABASE_ANON_KEY is not set in environment variables');
    }

    return { supabaseUrl, supabaseKey };
};

const initSupabase = () => {
    try {
        const { supabaseUrl, supabaseKey } = validateEnv();
        return createClient(supabaseUrl, supabaseKey);
    } catch (error) {
        console.error('Supabase initialization error:', error);
        throw error;
    }
};

const supabase = initSupabase();

export const deviceService = {
    async createDevice(name: string, deviceType: DeviceType, protocol: Protocol = 'websocket'): Promise<Device> {
        try {
            const prefix = deviceType === 'reCamera' ? 'RC' : 'GA';
            const deviceId = `Device-${prefix}${Math.random().toString(36).substring(2, 8).toUpperCase()}`;
            const clientId = `HeySalad_${deviceType}${Math.random().toString(36).substring(2, 6).toUpperCase()}`;
            const wsToken = `ws_${Math.random().toString(36).substring(2)}`;

            const newDevice = {
                deviceId,
                clientId,
                name,
                deviceType,
                status: 'offline' as DeviceStatus,
                lastSeen: new Date().toISOString(),
                projectId: 'default',
                wsToken,
                protocol,
                mqttUsername: protocol === 'mqtt' ? `device_${deviceId}` : null,
                mqttPassword: protocol === 'mqtt' ? Math.random().toString(36).substring(2) : null,
                storage: {
                    local: true,
                    supabase: true,
                    google: true
                }
            };

            const { data, error } = await supabase
                .from('userVideoStreamDevices')
                .insert([newDevice])
                .select()
                .single();

            if (error) throw error;

            if (!data) {
                throw new Error('No data returned from insert');
            }

            return {
                id: data.deviceId,
                clientId: data.clientId,
                name: data.name,
                deviceType: data.deviceType,
                status: data.status,
                lastSeen: new Date(data.lastSeen),
                projectId: data.projectId,
                wsToken: data.wsToken,
                protocol: data.protocol,
                mqttUsername: data.mqttUsername,
                mqttPassword: data.mqttPassword,
                storage: data.storage
            };
        } catch (error) {
            console.error('Error in createDevice:', error);
            throw error;
        }
    },

    async getDevices(): Promise<Device[]> {
        try {
            const { data, error } = await supabase
                .from('userVideoStreamDevices')
                .select('*')
                .order('createdAt', { ascending: false });

            if (error) throw error;

            return (data || []).map(item => ({
                id: item.deviceId,
                clientId: item.clientId,
                name: item.name,
                deviceType: item.deviceType,
                status: item.status as DeviceStatus,
                lastSeen: new Date(item.lastSeen),
                projectId: item.projectId,
                wsToken: item.wsToken,
                protocol: item.protocol,
                mqttUsername: item.mqttUsername,
                mqttPassword: item.mqttPassword,
                storage: item.storage
            }));
        } catch (error) {
            console.error('Error in getDevices:', error);
            throw error;
        }
    },

    async updateDevice(deviceId: string, updates: Partial<Device>): Promise<void> {
        try {
            const dbUpdates: any = {
                name: updates.name,
                status: updates.status,
                deviceType: updates.deviceType,
                lastSeen: updates.lastSeen?.toISOString(),
                projectId: updates.projectId,
                storage: updates.storage,
                protocol: updates.protocol,
                mqttUsername: updates.mqttUsername,
                mqttPassword: updates.mqttPassword
            };

            // Remove undefined values
            Object.keys(dbUpdates).forEach(key => 
                dbUpdates[key] === undefined && delete dbUpdates[key]
            );

            const { error } = await supabase
                .from('userVideoStreamDevices')
                .update(dbUpdates)
                .eq('deviceId', deviceId);

            if (error) throw error;
        } catch (error) {
            console.error('Error in updateDevice:', error);
            throw error;
        }
    },

    async updateDeviceStatus(deviceId: string, status: DeviceStatus): Promise<void> {
        try {
            const { error } = await supabase
                .from('userVideoStreamDevices')
                .update({
                    status,
                    lastSeen: new Date().toISOString()
                })
                .eq('deviceId', deviceId);

            if (error) throw error;
        } catch (error) {
            console.error('Error in updateDeviceStatus:', error);
            throw error;
        }
    },

    async deleteDevice(deviceId: string): Promise<void> {
        try {
            const { error } = await supabase
                .from('userVideoStreamDevices')
                .delete()
                .eq('deviceId', deviceId);

            if (error) throw error;
        } catch (error) {
            console.error('Error in deleteDevice:', error);
            throw error;
        }
    },

    async getDeviceById(deviceId: string): Promise<Device | null> {
        try {
            const { data, error } = await supabase
                .from('userVideoStreamDevices')
                .select('*')
                .eq('deviceId', deviceId)
                .single();

            if (error) throw error;
            if (!data) return null;

            return {
                id: data.deviceId,
                clientId: data.clientId,
                name: data.name,
                deviceType: data.deviceType,
                status: data.status,
                lastSeen: new Date(data.lastSeen),
                projectId: data.projectId,
                wsToken: data.wsToken,
                protocol: data.protocol,
                mqttUsername: data.mqttUsername,
                mqttPassword: data.mqttPassword,
                storage: data.storage
            };
        } catch (error) {
            console.error('Error in getDeviceById:', error);
            throw error;
        }
    },

    async updateMQTTCredentials(deviceId: string, mqttUsername: string, mqttPassword: string): Promise<void> {
        try {
            const { error } = await supabase
                .from('userVideoStreamDevices')
                .update({
                    mqttUsername,
                    mqttPassword,
                    updatedAt: new Date().toISOString()
                })
                .eq('deviceId', deviceId);

            if (error) throw error;
        } catch (error) {
            console.error('Error in updateMQTTCredentials:', error);
            throw error;
        }
    }
};