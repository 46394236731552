import { useState } from "react";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";

const HappyTomato = () => {
  const STATE_MACHINE_NAME = "HappyTomatoState";
  const HOVER_INPUT_NAME = "Hover";
  const CLICK_INPUT_NAME = "Jump";

  // Load the Rive animation file and set up the state machine
  const { rive, RiveComponent } = useRive({
    src: ".happy-tomato.riv", // Ensure this is the path to your Rive file
    stateMachines: STATE_MACHINE_NAME,
    autoplay: true,
  });

  // State machine inputs
  const hoverInput = useStateMachineInput(rive, STATE_MACHINE_NAME, HOVER_INPUT_NAME);
  const clickInput = useStateMachineInput(rive, STATE_MACHINE_NAME, CLICK_INPUT_NAME);

  const [isHovered, setIsHovered] = useState(false);

  // Event handlers
  const handleMouseEnter = () => {
    hoverInput && (hoverInput.value = true);
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    hoverInput && (hoverInput.value = false);
    setIsHovered(false);
  };

  const handleClick = () => {
    clickInput && clickInput.fire();
  };

  return (
    <div
      className="happy-tomato-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <RiveComponent className="happy-tomato-canvas" />
      <p>{isHovered ? "Sal says Hi!" : "Click Sal for a surprise!"}</p>
    </div>
  );
};

export default HappyTomato;
