import React, { useState } from 'react';
import { X } from 'lucide-react';
import recameraImg from '../../assets/recamera-photo.svg';
import groveAiImg from '../../assets/grove-ai.svg';
import { DeviceType } from '../../types/device';

interface DeviceFormProps {
  onSubmit: (data: { name: string; deviceType: DeviceType }) => void;
  onCancel: () => void;
}

export const DeviceForm: React.FC<DeviceFormProps> = ({ onSubmit, onCancel }) => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceType | null>(null);
  const [name, setName] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedDevice) return;
    onSubmit({ name, deviceType: selectedDevice });
  };

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <div className="flex justify-between items-center">
          <h2 className="card-title">New Device</h2>
          <button onClick={onCancel} className="btn btn-ghost btn-sm">
            <X className="w-4 h-4" />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div 
              className={`card bg-base-200 cursor-pointer hover:bg-base-300 transition-colors ${
                selectedDevice === 'reCamera' ? 'ring-2 ring-primary' : ''
              }`}
              onClick={() => setSelectedDevice('reCamera')}
            >
              <div className="card-body items-center text-center p-4">
                <img 
                  src={recameraImg} 
                  alt="reCamera" 
                  className="w-24 h-24 object-contain"
                />
                <h3 className="font-semibold mt-2">Seeedstudio reCamera</h3>
              </div>
            </div>

            <div 
              className={`card bg-base-200 cursor-pointer hover:bg-base-300 transition-colors ${
                selectedDevice === 'groveAI' ? 'ring-2 ring-primary' : ''
              }`}
              onClick={() => setSelectedDevice('groveAI')}
            >
              <div className="card-body items-center text-center p-4">
                <img 
                  src={groveAiImg} 
                  alt="Grove AI" 
                  className="w-24 h-24 object-contain"
                />
                <h3 className="font-semibold mt-2">Seeedstudio Grove AI</h3>
              </div>
            </div>
          </div>

          {selectedDevice && (
            <div className="form-control">
              <label className="label">
                <span className="label-text">Device Name</span>
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="input input-bordered"
                placeholder="Enter device name"
                required
              />
            </div>
          )}
          
          <div className="card-actions justify-end mt-4">
            <button type="button" onClick={onCancel} className="btn btn-ghost">
              Cancel
            </button>
            <button 
              type="submit" 
              className="btn btn-primary"
              disabled={!selectedDevice || !name}
            >
              Create Device
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};