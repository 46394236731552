// src/components/devices/config/HTTPSConfig.tsx
import React, { useState } from 'react';
import { Copy, RefreshCw } from 'lucide-react';
import { Device } from '../../../types/device';

interface Props {
  device: Device;
  onCopyUrl: (url: string) => void;
  onUpdateApiKey: (apiKey: string) => Promise<void>;
}

export const HTTPSConfig: React.FC<Props> = ({
  device,
  onCopyUrl,
  onUpdateApiKey,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const url = 'https://videobase-api.heysalad.app/v1/devices/data';

  const generateApiKey = (length = 32) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array.from(crypto.getRandomValues(new Uint8Array(length)))
      .map(x => charset[x % charset.length])
      .join('');
  };

  const handleGenerateNewKey = async () => {
    try {
      setIsGenerating(true);
      const newApiKey = generateApiKey();
      await onUpdateApiKey(newApiKey);
    } catch (err) {
      console.error('Error generating API key:', err);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="form-control">
        <label className="label">
          <span className="label-text">HTTPS URL</span>
        </label>
        <div className="input-group">
          <input
            type="text"
            value={url}
            readOnly
            className="input input-bordered w-full font-mono text-sm"
          />
          <button 
            onClick={() => onCopyUrl(url)} 
            className="btn btn-square"
            title="Copy URL"
          >
            <Copy className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">API Key</span>
        </label>
        <div className="input-group">
          <input
            type="text"
            value={device.clientId || 'No API key generated'}
            readOnly
            className="input input-bordered w-full font-mono"
          />
          <button 
            onClick={handleGenerateNewKey}
            className="btn"
            disabled={isGenerating}
            title="Generate new API key"
          >
            <RefreshCw className={`w-4 h-4 mr-2 ${isGenerating ? 'animate-spin' : ''}`} />
            {isGenerating ? 'Generating...' : 'Generate New'}
          </button>
        </div>
        <label className="label">
          <span className="label-text-alt text-warning">
            Generating a new key will invalidate the previous one
          </span>
        </label>
      </div>
    </div>
  );
};