/**
 * NodeREDSection Component
 * 
 * @fileoverview Provides a Node-RED function code display with copy functionality.
 */

import React from 'react';
import { Copy } from 'lucide-react';
import { Protocol } from '../../../types/device';

/** Props interface for NodeREDSection component */
interface NodeREDSectionProps {
  /** Node-RED function code to display */
  code: string;
  /** Callback function for copying code, receives the code as a parameter */
  onCopyCode: (code: string) => void;
  /** Protocol type for the device */
  protocol: Protocol;
}

/**
 * Component for displaying Node-RED integration code.
 * Provides syntax-highlighted code display and copy functionality.
 */
export const NodeREDSection: React.FC<NodeREDSectionProps> = ({
  code,
  onCopyCode,
}) => {
  /**
   * Handles the copy button click event.
   */
  const handleCopy = () => {
    onCopyCode(code);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Node-RED Function</h3>
        <button 
          onClick={handleCopy}
          className="btn btn-sm btn-outline gap-2"
          aria-label="Copy Node-RED code"
        >
          <Copy className="w-4 h-4" />
          Copy Code
        </button>
      </div>
      <div className="bg-base-300 rounded-lg p-4">
        <pre className="overflow-x-auto whitespace-pre text-sm">
          <code>{code}</code>
        </pre>
      </div>
    </div>
  );
};

export default NodeREDSection;