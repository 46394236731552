import React, { useState, useCallback } from 'react';
import { 
  Copy, 
  Eye, 
  EyeOff, 
  RefreshCw, 
  Check 
} from 'lucide-react';
import { Device } from '../../../types/device';
import MQTTInstructions from './instructions/MQTTInstructions1';

/**
 * Environment configuration for API endpoints.
 */
const ENV = {
  /** Base URL for the device registration API */
  API_URL: 'https://videobase-device-registration.heysalad.app',
  /** MQTT broker hostname */
  MQTT_BROKER: 'videobase-mqtt.heysalad.app'
} as const;

/**
 * Component configuration constants.
 */
const CONFIG = {
  PASSWORD_LENGTH: 16,
  COPY_TOOLTIP_DURATION: 2000,
  PASSWORD_CHARSET: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*",
  REGISTRATION_ENDPOINT: `${ENV.API_URL}/api/v1/device/register`
} as const;

interface Props {
  device: Device;
  mqttUsername: string;
  mqttPassword: string;
  onUpdateCredentials: (username: string, password: string) => Promise<void>;
  onCopyUrl?: (url: string) => Promise<void>;
  isTLS: boolean;
}

interface RegistrationResponse {
  status: 'success' | 'error';
  message?: string;
  error?: string;
}

export const MQTTConfig: React.FC<Props> = ({
  device,
  mqttUsername,
  mqttPassword,
  onUpdateCredentials,
  onCopyUrl,
  isTLS
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [registrationStatus, setRegistrationStatus] = useState<string | null>(null);
  const [registrationError, setRegistrationError] = useState<string | null>(null);
  const [copied, setCopied] = useState<string>('');

  const baseUrl = isTLS ? 'mqtts://' : 'mqtt://';
  const url = `${baseUrl}${ENV.MQTT_BROKER}:${isTLS ? '8883' : '1883'}`;

  /**
   * Registers the device with the MQTT broker using the exact payload format
   * demonstrated in the curl command.
   */
  const registerDevice = async (): Promise<void> => {
    try {
      setIsRegistering(true);
      setRegistrationStatus(null);
      setRegistrationError(null);

      const payload = {
        device_id: device.id,
        username: `device_${device.id}`,
        password: mqttPassword
      };

      const response = await fetch(CONFIG.REGISTRATION_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      const data: RegistrationResponse = await response.json();

      if (response.ok && data.status === 'success') {
        setRegistrationStatus('Device registered successfully');
        // Update the username to match the format used in curl command
        await onUpdateCredentials(`device_${device.id}`, mqttPassword);
      } else {
        throw new Error(data.error || 'Registration failed');
      }
    } catch (error) {
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'Failed to register device';
      setRegistrationError(errorMessage);
      console.error('Registration error:', error);
    } finally {
      setIsRegistering(false);
    }
  };

  /**
   * Generates a secure password following the same format as seen in the
   * successful curl examples.
   */
  const generatePassword = useCallback((): string => {
    const array = new Uint8Array(CONFIG.PASSWORD_LENGTH);
    crypto.getRandomValues(array);
    return Array.from(array)
      .map(x => CONFIG.PASSWORD_CHARSET[x % CONFIG.PASSWORD_CHARSET.length])
      .join('');
  }, []);

  /**
   * Handles the generation and registration process with the exact payload
   * structure demonstrated in the curl command.
   */
  const handleGenerateCredentials = async (): Promise<void> => {
    if (isRegistering) return;

    try {
      setIsRegistering(true);
      const newPassword = generatePassword();
      const newUsername = `device_${device.id}`;
      
      // First update credentials locally
      await onUpdateCredentials(newUsername, newPassword);
      // Then register with the server
      await registerDevice();
    } catch (error) {
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'Failed to generate credentials';
      setRegistrationError(errorMessage);
    }
  };

  const handleCopy = async (text: string, type: string): Promise<void> => {
    try {
      if (type === 'url' && onCopyUrl) {
        await onCopyUrl(text);
      } else {
        await navigator.clipboard.writeText(text);
      }
      setCopied(type);
      setTimeout(() => setCopied(''), CONFIG.COPY_TOOLTIP_DURATION);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="space-y-6">
      <div className="card bg-base-100 shadow-sm">
        <div className="card-body">
          <h3 className="card-title text-lg">Connection Details</h3>

          <div className="space-y-4">
            {/* MQTT URL Field */}
            <div className="form-control">
              <label className="label">
                <span className="label-text font-medium">MQTT Broker URL</span>
                <span className="label-text-alt">{isTLS ? 'TLS Enabled' : 'Standard'}</span>
              </label>
              <div className="input-group">
                <input
                  type="text"
                  value={url}
                  readOnly
                  className="input input-bordered w-full font-mono text-sm"
                />
                <button 
                  onClick={() => handleCopy(url, 'url')}
                  className="btn"
                >
                  {copied === 'url' ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
                </button>
              </div>
            </div>

            {/* Credentials Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Username Field */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-medium">Username</span>
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    value={mqttUsername}
                    readOnly
                    className="input input-bordered w-full font-mono text-sm"
                  />
                  <button 
                    onClick={() => handleCopy(mqttUsername, 'username')}
                    className="btn"
                  >
                    {copied === 'username' ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
                  </button>
                </div>
              </div>

              {/* Password Field */}
              <div className="form-control">
                <label className="label">
                  <span className="label-text font-medium">Password</span>
                </label>
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={mqttPassword}
                    readOnly
                    className="input input-bordered w-full font-mono text-sm"
                  />
                  <button
                    className="btn"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <EyeOff className="w-4 h-4" /> : <Eye className="w-4 h-4" />}
                  </button>
                  <button
                    className={`btn ${isRegistering ? 'loading' : ''}`}
                    onClick={handleGenerateCredentials}
                    disabled={isRegistering}
                  >
                    {!isRegistering && <RefreshCw className="w-4 h-4 mr-2" />}
                    {isRegistering ? 'Registering...' : 'Generate'}
                  </button>
                </div>
              </div>
            </div>

            {/* Status Messages */}
            {registrationStatus && (
              <div className="alert alert-success">
                <Check className="w-6 h-6" />
                <span>{registrationStatus}</span>
              </div>
            )}

            {registrationError && (
              <div className="alert alert-error">
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>{registrationError}</span>
              </div>
            )}
          </div>
        </div>
      </div>

      <MQTTInstructions
        deviceId={device.id}
        mqttUsername={mqttUsername}
        brokerUrl={url}
        isTLS={isTLS}
      />
    </div>
  );
};

export default MQTTConfig;