import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { AlertCircle } from 'lucide-react';
import icon from '../../assets/icon.svg';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  address: string;
  location: string;
  profession: string;
  numberOfDevices: number;
  referralCode: string;
  projectName: string;
  useType: 'personal' | 'school' | 'work' | 'business' | '';
}

export const SignUpForm: React.FC = () => {
  const [formData, setFormData] = useState<SignUpFormData>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    address: '',
    location: '',
    profession: '',
    numberOfDevices: 1,
    projectName: '',
    referralCode: '',
    useType: ''
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { signUp } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      await signUp(formData.email, formData.password);
      // Here you would also save the additional user data to your database
      navigate('/devices');
    } catch (err) {
      setError('Failed to create account');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-base-200 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <div className="card bg-white shadow-xl">
          <div className="card-body">
            <div className="flex items-center justify-center mb-8">
              <img src={icon} alt="Videobase Logo" className="w-8 h-8 mr-2" />
              <h1 className="text-3xl font-bold text-black">Videobase</h1>
            </div>
            <div className="text-center mb-8">
              <h2 className="text-2xl font-semibold mb-2 text-[#ed4c4c]">Create Your Account</h2>
              <p className="text-base-content/70">
                Join Videobase to manage your IoT video devices effectively
              </p>
            </div>

            {error && (
              <div className="alert alert-error mb-6">
                <AlertCircle className="w-5 h-5" />
                <span>{error}</span>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Personal Information Section */}
              <div className="card bg-white p-6">
                <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">First Name*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Last Name*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Email*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Password*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                      minLength={8}
                    />
                    <label className="label">
                      <span className="label-text-alt">Minimum 8 characters</span>
                    </label>
                  </div>
                </div>
              </div>

              {/* Contact Information Section */}
              <div className="card bg-white p-6">
                <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Phone Number*</span>
                    </label>
                    <input
                      type="tel"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Location*</span>
                    </label>
                    <input
                      type="text"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      className="input input-bordered"
                      placeholder="City, Country"
                      required
                    />
                  </div>

                  <div className="form-control md:col-span-2">
                    <label className="label">
                      <span className="label-text">Address*</span>
                    </label>
                    <textarea
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      className="textarea textarea-bordered h-20"
                      required
                    />
                  </div>
                </div>
              </div>

              {/* Professional Information Section */}
              <div className="card bg-white p-6">
                <h2 className="text-xl font-semibold mb-4">Professional Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Profession*</span>
                    </label>
                    <input
                      type="text"
                      name="profession"
                      value={formData.profession}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Use Type</span>
                    </label>
                    <select
                      name="useType"
                      value={formData.useType}
                      onChange={handleChange}
                      className="select select-bordered"
                    >
                      <option value="">Select usage type</option>
                      <option value="personal">Personal</option>
                      <option value="school">School</option>
                      <option value="work">Work</option>
                      <option value="business">Business</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Project Information Section */}
              <div className="card bg-white p-6">
                <h2 className="text-xl font-semibold mb-4">Project Information</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Project Name*</span>
                    </label>
                    <input
                      type="text"
                      name="projectName"
                      value={formData.projectName}
                      onChange={handleChange}
                      className="input input-bordered"
                      required
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Number of Devices*</span>
                    </label>
                    <input
                      type="number"
                      name="numberOfDevices"
                      value={formData.numberOfDevices}
                      onChange={handleChange}
                      className="input input-bordered"
                      min="1"
                      required
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">Referral Code (Optional)</span>
                    </label>
                    <input
                      type="text"
                      name="referralCode"
                      value={formData.referralCode}
                      onChange={handleChange}
                      className="input input-bordered"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                <button
                  type="submit"
                  className="btn btn-lg"
                  style={{ backgroundColor: '#ed4c4c', color: '#ffffff' }}
                  disabled={loading}
                >
                  {loading ? (
                    <span className="loading loading-spinner"></span>
                  ) : (
                    'Create Account'
                  )}
                </button>

                <p className="text-center">
                  Already have an account?{' '}
                  <Link to="/login" className="link" style={{ color: '#ed4c4c' }}>
                    Sign in
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;