import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom'; // Added useParams
import { AuthProvider, useAuth } from './contexts/AuthContext';
import DashboardLayout from './layouts/DasboardLayout';
import Landing from './pages/Landing';
import { LoginForm } from './components/auth/LoginForm';
import { SignUpForm } from './components/auth/SignUpForm';
import Dashboard from './pages/Dashboard';
import DeviceInfo from './pages/Devices/DeviceInfo';
import Devices from './pages/Devices/Devices';
import DeviceListening from './components/devices/DeviceListening';
import Viewer from './pages/Viewer';
import Stats from './pages/Stats';
import Settings from './pages/Settings';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};

const DeviceListeningWrapper: React.FC = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  return deviceId ? <DeviceListening deviceId={deviceId} /> : null;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignUpForm />} />
          
          {/* Protected routes */}
          <Route element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/devices" element={<Devices />} />
            <Route path="/devices/:deviceId" element={<DeviceInfo />} />
            <Route path="/devices/:deviceId/listening" element={<DeviceListeningWrapper />} />
            <Route path="/viewer" element={<Viewer />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;