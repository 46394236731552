// src/components/devices/config/ProtocolToggle.tsx

import React from 'react';
import { Wifi, Globe, Radio } from 'lucide-react';
import type { Protocol } from '../../../types/device';

/**
 * Props interface for the ProtocolToggle component.
 */
interface ProtocolToggleProps {
  /** Currently selected protocol */
  selectedProtocol: Protocol;
  /** Callback when protocol selection changes */
  onChange: (protocol: Protocol) => void;
  /** Callback when TLS setting changes */
  onTLSChange: (enabled: boolean) => void;
  /** Current TLS enabled state */
  isTLS: boolean;
}

/**
 * Component for selecting device communication protocol and TLS settings.
 *
 * @param props - Component properties
 * @returns A protocol selection interface with TLS toggle
 */
const ProtocolToggle: React.FC<ProtocolToggleProps> = ({
  selectedProtocol,
  onChange,
  onTLSChange,
  isTLS
}: ProtocolToggleProps) => {
  const protocols: Protocol[] = ['mqtt', 'https', 'websocket'];

  /**
   * Gets the icon component for a given protocol.
   *
   * @param protocol - The protocol to get an icon for
   * @returns The corresponding Lucide icon component
   */
  const getIcon = (protocol: Protocol) => {
    switch (protocol) {
      case 'mqtt':
        return <Wifi className="w-4 h-4" />;
      case 'https':
        return <Globe className="w-4 h-4" />;
      case 'websocket':
        return <Radio className="w-4 h-4" />;
    }
  };

  return (
    <div className="card bg-base-100">
      <div className="card-body">
        <h3 className="card-title">
          <Globe className="w-5 h-5" />
          Connection Protocol
        </h3>

        <div className="grid grid-cols-3 gap-4 mt-4">
          {protocols.map((protocol) => (
            <button
              key={protocol}
              className={`btn ${selectedProtocol === protocol ? 'btn-primary' : 'btn-outline'}`}
              onClick={() => onChange(protocol)}
            >
              {getIcon(protocol)}
              <span className="ml-2">{protocol.toUpperCase()}</span>
            </button>
          ))}
        </div>

        {selectedProtocol !== 'https' && (
          <div className="form-control mt-4">
            <label className="label cursor-pointer">
              <span className="label-text">Enable TLS Security</span>
              <input
                type="checkbox"
                className="toggle toggle-primary"
                checked={isTLS}
                onChange={(e) => onTLSChange(e.target.checked)}
              />
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProtocolToggle;