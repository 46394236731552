import { FC, useState } from 'react';
import { Camera, Maximize, Minimize, RotateCw, ZoomIn, ZoomOut } from 'lucide-react';
import { useParams } from 'react-router-dom';

const Viewer: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const rotateCamera = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const zoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const zoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <Camera className="w-6 h-6 text-[#ed4c4c]" />
            <h1 className="text-2xl font-bold text-gray-800">Viewer</h1>
          </div>
          <button
            className="btn btn-sm bg-[#ed4c4c] hover:bg-[#faa09a] text-white border-none gap-2"
            onClick={toggleFullscreen}
          >
            {isFullscreen ? (
              <>
                <Minimize className="w-4 h-4" />
                Exit Fullscreen
              </>
            ) : (
              <>
                <Maximize className="w-4 h-4" />
                Fullscreen
              </>
            )}
          </button>
        </div>

        <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-gray-800">Camera {id}</h2>
            <div className="flex gap-2">
              <button
                className="btn btn-sm bg-[#ffd0cd] hover:bg-[#faa09a] border-none text-[#ed4c4c]"
                onClick={rotateCamera}
              >
                <RotateCw className="w-4 h-4" />
              </button>
              <button
                className="btn btn-sm bg-[#ffd0cd] hover:bg-[#faa09a] border-none text-[#ed4c4c]"
                onClick={zoomIn}
              >
                <ZoomIn className="w-4 h-4" />
              </button>
              <button
                className="btn btn-sm bg-[#ffd0cd] hover:bg-[#faa09a] border-none text-[#ed4c4c]"
                onClick={zoomOut}
              >
                <ZoomOut className="w-4 h-4" />
              </button>
            </div>
          </div>

          <div
            className="bg-gray-100 rounded-lg p-4 h-[480px] flex justify-center items-center"
            style={{
              transform: `rotate(${rotation}deg) scale(${zoom})`,
            }}
          >
            <img
              src={`https://picsum.photos/800/600?random=${id}`}
              alt="Camera Feed"
              className="max-w-full max-h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Viewer;